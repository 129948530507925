<template>
    <div class="app-menu single-box-col-3">
        <div class="md-layout md-alignment-top-center">
            <md-avatar class="md-avatar-icon md-large">
                <img class="menu-avatar" :src="avatarUrl" alt="Avatar">
            </md-avatar>
        </div>
        <div class="md-layout md-alignment-top-center">
            <div class="avatar-name">
                {{currentUser.email}}
            </div>
        </div>
        <md-list class="menu-list">
            <template v-for="menuItem in menuItems">
                <template v-if="typeof menuItem.children == 'undefined'">
                    <md-list-item :to="menuItem.url">
                        <img :src="'../assets/images/' + menuItem.icon" alt="">
                        <span class="md-list-item-text">{{menuItem.name}}</span>
                    </md-list-item>
                </template>
                <template v-else>
                    <md-list-item md-expand>
                        <img :src="'../assets/images/' + menuItem.icon" alt="">
                        <span class="md-list-item-text">{{menuItem.name}}</span>
                        <md-list slot="md-expand">
                            <md-list-item class="md-inset" v-for="submenuItem in menuItem.children"
                                          :to="submenuItem.url">
                                <img :src="'../assets/images/' + submenuItem.icon" alt="">
                                <span class="md-list-item-text">{{submenuItem.name}}</span>
                            </md-list-item>
                        </md-list>
                    </md-list-item>
                </template>
            </template>
        </md-list>
        <div class="md-layout md-alignment-bottom-center">
            <md-button
                    class="md-raised"
                    @click="attempt_logout"
            >Log out
            </md-button>
        </div>

    <md-dialog-confirm
            :md-active.sync="showLogoutDialog"
            md-title="Log out"
            md-content="Are you sure you want to log out?"
            md-confirm-text="Yes"
            md-cancel-text="No"
            @md-confirm="logout"/>
    </div>
</template>

<script>
    import menu from "../menu.js";
    import firebase from "firebase";

    import {bus} from "../main";

    export default {
        name: "AppMenu",
        data() {
            return {
                currentUser: null,
                showLogoutDialog: false,
                avatarUrl: null,
                menuItems: null
            };
        },
        created() {
            this.avatarUrl = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTKdScDW7sBB1L4abbOjKk1sLwyf0aPoD3nwleLl4hKuOVrlIiU';
            this.menuItems = menu.items;

            this.currentUser = firebase.auth().currentUser;
        },
        methods: {
            logout() {
                console.log('logging out...');

                let that = this;

                firebase.auth().signOut().then(() => {
                    that.$router.replace('login');
                })
            },
            attempt_logout() {
                console.log('attempting logout...');
                // this.$refs['logout-modal'].show();
                this.showLogoutDialog = true;
            }
        }
    }
</script>

<style scoped lang="scss">
    $top-margin: 144px;

    .md-avatar {
        margin-top: 60px;
        width: 144px;
        height: 144px;
        border: solid 2px #3A63FA;
        border-radius: 72px;
    }

    .avatar-name {
        color: #222222;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 60px;
    }

    /*
    .app-menu {
        position: absolute;
        top: $top-margin;
        left: 0px;
        bottom: 0px;
        z-index: 1;
        width: 320px;
        background-color: white;
        border-top-right-radius: 32px;
    }
    */

    .menu-list {
        padding-left: 40px;
        list-style: none;
    }

    .md-list {
        width: 320px;
        max-width: 100%;
        display: inline-block;
        vertical-align: top;
        /*border: 1px solid rgba(#000, .12);*/
    }
</style>