export default {
    items: [
        {
            name: 'Dashboard',
            url: '/sections/dashboard',
            icon: 'dashboard-icon.svg',
            scopes: ['']
        },
        {
            name: 'Membership card',
            icon: 'memership-icon.svg',
            scopes: [''],
            children: [
                {
                    name: 'Establishment',
                    url: '/sections/establishments',
                    icon: 'dashboard-icon.svg',
                    scopes: ['']
                },
                {
                    name: 'Benefits',
                    url: '/sections/details',
                    icon: 'dashboard-icon.svg',
                    scopes: ['']
                },
            ]
        }
    ]
};
