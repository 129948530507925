<template>
    <div class="app">
        <div class="app-body">
            <AppHeader></AppHeader>
            <div class="main flexbox flex-top wrap">
                <AppMenu></AppMenu>
                <router-view></router-view>
            </div>
            <slideout-panel></slideout-panel>
        </div>
    </div>
</template>

<script>
    import {bus} from "../main";

    import firebase from "firebase";

    import AppHeader from './AppHeader';
    import AppMenu from './AppMenu';

    export default {
        name: "DefaultContainer",

        components: {
            AppHeader,
            AppMenu,
        },

        watch() {

        },

        data() {
            return {
                user: null,
                hideNav: false
            };
        },

        created: function () {
            const currentUser = firebase.auth().currentUser;

            console.log("------ DEFAULT CONTAINER ------");
            console.log(currentUser.uid);

            console.log('init');
            let that = this;

            let router_ref = this.$router;
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    // User is signed in.
                    var displayName = user.displayName;
                    var email = user.email;
                    var emailVerified = user.emailVerified;
                    var photoURL = user.photoURL;
                    var isAnonymous = user.isAnonymous;
                    var uid = user.uid;
                    var providerData = user.providerData;

                    console.log('uid: ' + uid);

                    that.user = {};
                    that.user.img = user.photoURL;
                    that.user.email = user.email;

                } else {
                    console.log('signed out');
                    router_ref.replace('/pages');
                }
            });
        },
    }
</script>

<style scoped lang="scss">
    $header-height: 30%;

    .decor-top-right {
        position: absolute;
        width: 100%;
        height: $header-height;
        background-image: url("../assets/images/decor-top-right.svg");
        background-position: right top;
        background-repeat: no-repeat;
    }

    .main>div {
        height: inherit;
    }

</style>