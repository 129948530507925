<template>
    <section class="flexbox flex-top wrap app-header">
        <div class="single-box-col-4 box logo">
            <div class="icon-container">
                <img class="admin-logo" src="../assets/images/admin_logo.svg" alt="Aussiety">
            </div>
        </div>
        <div class="single-box-col-4 box">
            <div class="md-layout-item md-layout md-alignment-center-center md-size-100 welcome"><h1>Welcome!</h1></div>
        </div>
        <div class="single-box-col-4 box search">
            <input class="search-box" v-model="searchFor" placeholder="Search" type="text">
        </div>
    </section>
</template>

<script>
    import {bus} from "../main";

    export default {
        name: "AppHeader",
        data() {
            return {
                searchFor: null,
            };
        },
        watch: {
            searchFor() {
                bus.$emit("searchForUpdated", this.searchFor);
            }
        },
        created() {
            let that = this;
            bus.$on("searchForCleared", function() {
                that.searchFor = null;
            });
        }
    }
</script>

<style lang="scss">
    $logo-width: 150px;

    @import url('https://fonts.googleapis.com/css?family=Red+Hat+Display:400,500,700,900&display=swap');

    h1, h2, h3, h4, h5, h6, p, input {
        font-family: 'Red Hat Display', sans-serif;
    }
    h2 {
        font-size: 1.5rem;
    }
    .flexbox {
    display: flex;
    }    
    .flex-center {
    align-items: center;
    justify-content: center;
    }
    .wrap {
    flex-wrap: wrap;
    }
    .single-box-col-1{
        width: 8.333333333333333%;
        box-sizing: border-box;
    }
    .single-box-col-2{
        width: 16.6666666666666%;
        box-sizing: border-box;
    }
    .single-box-col-3{
        width: 25%;
        box-sizing: border-box;
    }
    .single-box-col-4{
        width: 33.3333333333%;
        box-sizing: border-box;
    }
    .single-box-col-5{
        width: 41.6666666667%;
        box-sizing: border-box;
    }
    .single-box-col-6{
        width: 50%;
        box-sizing: border-box;
    }
    .single-box-col-7{
        width: 58.3333333333%;
        box-sizing: border-box;
    }
    .single-box-col-8{
        width: 66.6666666667%;
        box-sizing: border-box;
    }
    .single-box-col-9{
        width: 75%;
        box-sizing: border-box;
    }
    .single-box-col-10{
        width: 83.3333333333%;
        box-sizing: border-box;
    }
    .single-box-col-11{
        width: 91.6666666667;
        box-sizing: border-box;
    }
    .single-box-col-12{
        width: 100%;
        box-sizing: border-box;
    }
    /* App */
    .app, .app-body {
        height: 100%;
    }
    .app-body {
        position: relative;
    }
    .app-header {
        min-height: 250px;
        background: url('../assets/images/header-bg.svg');
        background-size: cover;
        background-position: center;
        padding: 35px 0;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .app-menu {
        position: relative;
        background-color: var(--white);
    }
    .main-dashboard-establishments {
        background-color: #f3f5f9;
    }
    .box {
        height: 100%;
    }
    .box.logo {
        align-items: flex-start !important;
        justify-content: left !important;
    }
    .single-box-col-4.box {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h1 {
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1px;
    }
    .admin-logo {
        width: $logo-width;
        height: 100%;
    }
    .welcome {
        color: white;
        font-weight: normal;
        font-size: 40px;
        height: 80px;
        margin: 0px;
    }
    .box.search input {
        background: rgba(255, 255, 255, .2);
        border: none;
        padding: 10px 50px 10px 10px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        border-radius: 50px;
        outline: none;
        color: #fff;
        font-weight: 500;
    }
    .box.search input::placeholder {
        color: #fff;
    }
    .btn-secondary.rounded-pill {
        padding: 10px 20px;
        min-width: 150px;
    }
    .google-map {
        border-radius: 20px;
    }
    input {
        border: none;
        color: var(--gray);
    }
    @media screen and (min-width: 769px) {
        .main {
            min-height: 700px;
        }
        .admin-logo {
            margin: 0px 0px 0px 40px;
        }
        .app-header h1 {
            margin-top: 50px;
        }
        .app-menu {
            bottom: 50px;
            border-top-right-radius: 20px;
        }
        .box.search {
            justify-content: flex-end;
            align-items: flex-start;
            padding: 0 50px;
        }
    }
    @media screen and (max-width: 768px) {
        .single-box-col-1, .single-box-col-2, .single-box-col-3, .single-box-col-4, .single-box-col-5, .single-box-col-6, .single-box-col-7, .single-box-col-8, .single-box-col-9, .single-box-col-10, .single-box-col-11 {
            width: 100% !important;
            box-sizing: border-box;
        }
        .box.logo {
            justify-content: center !important;
        }
        .admin-logo {
            width: 120px;
        }
    }
</style>